import auth from "@/auth/msal-authprovider.js";
import Fetcher from "@/util/fetcher.js";
import { dateUtil } from "@/util/DateUtil";
const TIMEZONE_OFFEST = dateUtil.getTimeZoneOffset();

const API_URL = process.env.VUE_APP_API_URL;

const API_SCOPES = {
  ACCESS_AS_USER: "api://3243678e-ff51-4566-b963-ffc1eab9e467/access_as_user"
};
const API_REQUESTS = {
  // TOKEN: {
  //   endpoint: API_URL + "/api/surveys/token",
  //   method: "GET",
  //   options: {
  //     scopes: [API_SCOPES.ACCESS_AS_USER]
  //   }
  // },
  USER: {
    endpoint: API_URL + "/api/users",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  LOCALIZATION: {
    endpoint: API_URL + "/api/localization",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  INVITATION_QUEUE: {
    endpoint: API_URL + "/api/invitation-queue",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  EMAIL: {
    endpoint: API_URL + "/api/email",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  SMS_ACCOUNTS: {
    endpoint: API_URL + "/api/smsAccounts",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  STUDY: {
    endpoint: API_URL + "/api/studies",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  CHANGE_LOG: {
    endpoint: API_URL + "/api/changelog",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  PROJECT: {
    endpoint: API_URL + "/api/projects",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  PRIVACY_POLICIES: {
    endpoint: API_URL + "/api/privacyPolicies",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  STUDY_TEMPLATES: {
    endpoint: API_URL + "/api/studyTemplates",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  PROJECT_TEMPLATES: {
    endpoint: API_URL + "/api/projectTemplates",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  ASSETS: {
    endpoint: API_URL + "/api/assets",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },

  ASSET_COLLECTIONS: {
    endpoint: API_URL + "/api/asset-collections",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  IMAGE_QUESTIONS: {
    endpoint: API_URL + "/api/image-questions",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  CAMPAIGNS: {
    endpoint: API_URL + "/api/campaigns",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  JOBS: {
    endpoint: API_URL + "/api/dataflowjobs",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  FEATURE_MATRIX: {
    endpoint: API_URL + "/api/feature-matrix",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  // SURVEY: {
  //   endpoint: API_URL + "/api/surveys",
  //   options: {
  //     scopes: [API_SCOPES.ACCESS_AS_USER]
  //   }
  // },
  CURATIONS: {
    endpoint: API_URL + "/api/curations",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  VEHICLE_CURATION: {
    endpoint: API_URL + "/api/vehicle",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  PHONE_CURATION: {
    endpoint: API_URL + "/api/phone",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  CHANNELS: {
    endpoint: API_URL + "/api/channels",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  SURVEYS: {
    endpoint: API_URL + "/api/surveys",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  ASKIA_SURVEYS: {
    endpoint: API_URL + "/api/askia/surveys",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  OUTLIERS: {
    endpoint: API_URL + "/api/outliers",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  DOMAINS: {
    endpoint: API_URL + "/api/domains",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  }
};

const DNC_TYPES = { EMAIL: { path: "email/export", name: "Email" }, PHONE: { path: "phone/export", name: "Phone" } };

class ProjectAdminApi {
  constructor() {
    this.fetcher = new Fetcher(opts => {
      return auth.getTokenRedirect(opts).then(token => {
        return token.accessToken;
      });
    });
    this.API_REQUESTS = API_REQUESTS;
  }

  // ////////////////////
  // FEATURE MATRIX
  // ////////////////////
  getDataDictionaryList(activeOnly) {
    var qry = activeOnly ? "?active=true" : "";
    return this.fetcher.getJson(API_REQUESTS.FEATURE_MATRIX, `data-dictionary-list${qry}`, "GET", null).then(r => {
      return r;
    });
  }
  saveFeatureMatrixFeature(feature, surveyId, versionId) {
    return this.fetcher.getJson(API_REQUESTS.FEATURE_MATRIX, `survey/${surveyId}/version/${versionId}/save-feature`, "POST", feature).then(r => {
      return r;
    });
  }
  getFeatureMatrixRules(surveyId, versionId) {
    return this.fetcher.getJson(API_REQUESTS.FEATURE_MATRIX, `survey/${surveyId}/version/${versionId}`, "GET", null).then(r => {
      return r;
    });
  }
  getFeatureMatrixVersionList(surveyId) {
    return this.fetcher.getJson(API_REQUESTS.FEATURE_MATRIX, `survey/${surveyId}`, "GET", null).then(r => {
      return r;
    });
  }
  getDataDictionary(dataDictionary) {
    return this.fetcher.getJson(API_REQUESTS.FEATURE_MATRIX, `data-dictionary`, "POST", dataDictionary).then(r => {
      return r;
    });
  }
  publishFeatureMatrix(surveyId, versionId, versionName) {
    var data = { versionName: versionName };
    return this.fetcher.getJson(API_REQUESTS.FEATURE_MATRIX, `survey/${surveyId}/version/${versionId}/publish`, "POST", data).then(r => {
      return r;
    });
  }
  deleteFeatureMatrixRule(surveyId, versionId, featureId) {
    return this.fetcher
      .getJson(API_REQUESTS.FEATURE_MATRIX, `survey/${surveyId}/version/${versionId}/feature/${featureId}`, "DELETE", null)
      .then(r => {
        return r;
      });
  }
  createFeatureMatrixVersion(surveyId, versionName) {
    return this.fetcher.getJson(API_REQUESTS.FEATURE_MATRIX, `survey/${surveyId}/version/${versionName}/create`, "POST", null).then(r => {
      return r;
    });
  }
  // getFirebaseToken() {
  //   return this.fetcher.getJson(API_REQUESTS.TOKEN, null, "GET", null);
  // }
  getCurrentUser() {
    return this.fetcher.getJson(API_REQUESTS.USER, "current", "GET", null);
  }

  getStudies() {
    return this.fetcher.getJson(API_REQUESTS.STUDY, null, "GET", null);
    // .then(r => {
    //   return r.studies;
    // });
  }
  getAllStudies() {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `all`, "GET", null);
  }
  getStudyById(id) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, id, "GET", null).then(s => {
      return s;
    });
  }
  getExtendedStudyById(id) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${id}/extended`, "GET", null).then(s => {
      return s;
    });
  }
  importStudy(study, file) {
    var form = new FormData();

    form.append("TemplateId", study.template);
    form.append("SurveyId", study.surveyId);
    form.append("CopyKeyStaff", study.copyKeyStaff);
    form.append("StudyFile", file);
    form.append("ImportExtendedData", study.importExtendedData);
    form.append("Domain", study.domain);

    return this.fetcher.getJson(API_REQUESTS.STUDY, `import`, "POST", form, null).then(r => {
      return r.id;
    });
  }
  createStudy(study) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, null, "POST", study).then(r => {
      return r.id;
    });
  }
  editStudy(studyId, study) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, studyId, "PUT", study).then(r => {
      return r.updateTime;
    });
  }
  createStudyType(studyType) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, "studyType", "POST", studyType).then(r => {
      return r.id;
    });
  }
  getStudyTypes() {
    return this.fetcher.getJson(API_REQUESTS.STUDY, "studyTypes", "GET", null).then(r => {
      return r.studyTypes;
    });
  }
  getStudyType(typeId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `studyTypes/${typeId}`, "GET", null).then(r => {
      return r.type;
    });
  }
  updateStudyType(studyTypeId, studyType) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `studyTypes/update/${studyTypeId}`, "POST", studyType).then(r => {
      return r.updateTime;
    });
  }

  deleteStudyType(studyTypeId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `studyTypes/delete/${studyTypeId}`, "DELETE", null).then(r => {
      return r.deleteTime;
    });
  }
  updateStudyTypeOptions(studyId, studyType) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `studyTypes/study/${studyId}`, "POST", studyType).then(r => {
      return r.id;
    });
  }
  getDataWareHouseTables(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/tables`, "GET", null).then(r => {
      return r;
    });
  }
  getDataWareHouseTableFields(studyId, tableName) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/table/${tableName}/fields`, "GET", null).then(r => {
      return r;
    });
  }
  addStudyDataProtectionRule(studyId, rule, retroactive) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/rule`, "POST", {
        Rule: rule,
        Retroactive: retroactive
      })
      .then(r => {
        return r.updateTime;
      });
  }
  updateStudyDataProtectionRule(studyId, ruleId, rule, retroactive) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/rule/${ruleId}`, "PUT", {
        Rule: rule,
        Retroactive: retroactive
      })
      .then(r => {
        return r.updateTime;
      });
  }
  deleteStudyDataProtectionRule(studyId, ruleId, retroactive) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/rule/${ruleId}`, "DELETE", {
        Retroactive: retroactive
      })
      .then(r => {
        return r.updateTime;
      });
  }
  getProjects() {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, null, "GET", null).then(r => {
      return r.projects;
    });
  }
  getProjectById(id) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, id, "GET", null).then(r => {
      return r;
    });
  }

  getChangeLogEntries(dat) {
    var path = "";

    if (dat.studyId) path = `studies/${dat.studyId}`;
    else if (dat.projectId) path = `projects/${dat.projectId}`;

    return this.fetcher.getJson(API_REQUESTS.CHANGE_LOG, path, "POST", dat).then(r => {
      return r.entries;
    });
  }

  //////////////////////
  // REMEDIATION
  //////////////////////

  setRemediation(projectId, respondentId, remedy) {
    remedy.respondentId = respondentId;
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/remediation/${respondentId}`, "PUT", remedy).then(r => {
      return r.id;
    });
  }
  deleteRemediation(projectId, remediationId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/remediation/${remediationId}`, "DELETE").then(r => {
      return r.deleted;
    });
  }

  //////////////////////
  // PROJECTS
  //////////////////////
  createProject(project) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, null, "POST", project).then(r => {
      return r.id;
    });
  }
  updateProject(projectId, project) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}`, "PUT", project).then(r => {
      return r.id;
    });
  }
  getProcessingQueueWaveMetrics(projectId, studyId, waveId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/studies/${studyId}/waves/${waveId}`, "GET", null).then(r => {
      return r.metrics;
    });
  }
  getProcessingQueueStatusCounts(projectId, groupedByWave) {
    var query = groupedByWave ? "?GroupByWave=true" : "";
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/processingQueueCounts${query}`, "GET", null).then(r => {
      return r;
    });
  }
  getProcessingQueueIssueCounts(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/processingQueueIssueCounts`, "GET", null).then(r => {
      return r;
    });
  }
  getProcessingQueueStepProgress(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/processingQueueStepProgress`, "GET", null).then(r => {
      return r;
    });
  }
  getProcessingQueueProcessDates(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/processingQueueProcessDates`, "GET", null).then(r => {
      return r;
    });
  }
  toggleHoldInQueue(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/processingQueueRunStatus/toggle`, "PUT", null).then(r => {
      return r;
    });
  }
  runProcessingQueue(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/launchProcessingQueue`, "POST", null).then(r => {
      return r;
    });
  }
  runPullCompletes(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/launchPullCompletes`, "POST", null).then(r => {
      return r;
    });
  }
  getSurveyResponseCounts(projectId, filters) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/surveyResponseCounts`, "POST", filters).then(r => {
      return r;
    });
  }
  getSurveyResponseStatusCounts(projectId, filters) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/surveyResponseStatusCounts`, "POST", filters).then(r => {
      return r;
    });
  }
  getSurveyResponsePphAverage(projectId, filters) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/surveyResponsePphAverage`, "POST", filters).then(r => {
      return r;
    });
  }
  getSurveyResponseScrubReasonCounts(projectId, filters) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/surveyResponseScrubReasonCounts`, "POST", filters).then(r => {
      return r;
    });
  }
  getSurveyResponseProcessDates(projectId, filters) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/surveyResponseProcessDates`, "POST", filters).then(r => {
      return r;
    });
  }
  getSurveyResponseCountsBySurveyDate(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/surveyResponseCountsBySurveyDate`, "GET", null).then(r => {
      return r;
    });
  }
  requeueSurveyResponses(projectId, requeueData) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/requeueSurveyResponses`, "POST", requeueData).then(r => {
      return r;
    });
  }
  getProcessingQueueIssueRecords(projectId, issueId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/getQueueIssueEntries`, "POST", { issue: issueId }).then(r => {
      return r.issues;
    });
  }

  getProcessQueueRespResponses(projectId, respId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/queue/respondents/${respId}`, "GET", null);
  }
  requeueRespondent(projectId, respondentId, reEnrichData) {
    return this.fetcher
      .getJson(API_REQUESTS.PROJECT, `${projectId}/requeueRespondent/${respondentId}`, "POST", {
        respondentId: respondentId,
        reEnrich: reEnrichData
      })
      .then(r => {
        return r;
      });
  }
  requeueAll(projectId, reEnrichData) {
    return this.fetcher
      .getJson(API_REQUESTS.PROJECT, `${projectId}/requeueAll`, "POST", {
        reEnrich: reEnrichData
      })
      .then(r => {
        return r;
      });
  }
  requeueWave(projectId, requeueData) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/requeueWave`, "POST", requeueData).then(r => {
      return r;
    });
  }
  requeueDateRange(projectId, requeueData) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/requeueDateRange`, "POST", requeueData).then(r => {
      return r;
    });
  }
  getSurveyVariables(surveyId) {
    return this.fetcher.getJson(API_REQUESTS.SURVEYS, `${surveyId}/surveyVariables`, "GET", null).then(r => {
      return r;
    });
  }
  getProjectColumns(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/projectColumns`, "GET", null).then(r => {
      return r;
    });
  }
  updateProjectColumn(projectId, projectColumn) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/projectColumn`, "PUT", projectColumn).then(r => {
      return r;
    });
  }
  updateProjectSampleView(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/update-sample-view`, "POST", null).then(r => {
      return r;
    });
  }

  ///////////////////////////////////////////////////////////////////////
  // PROCESSING STEPS (shared repo bewtween Project and ProjectTemplates)
  ///////////////////////////////////////////////////////////////////////

  getProjectSteps(projectId, apiRequestObj) {
    return this.fetcher.getJson(apiRequestObj, `${projectId}/processingSteps`, "GET", null).then(r => {
      return r.steps;
    });
  }
  getProjectStep(projectId, stepId, apiRequestObj) {
    return this.fetcher.getJson(apiRequestObj, `${projectId}/processingSteps/${stepId}`, "GET", null).then(r => {
      return r.step;
    });
  }
  createProjectStep(projectId, step, apiRequestObj) {
    return this.fetcher.getJson(apiRequestObj, `${projectId}/processingSteps`, "POST", step).then(r => {
      return r.id;
    });
  }
  updateProjectStep(projectId, step, apiRequestObj) {
    return this.fetcher.getJson(apiRequestObj, `${projectId}/processingSteps/${step.id}`, "PUT", step).then(r => {
      return r;
    });
  }
  deleteProjectStep(projectId, stepId, apiRequestObj) {
    return this.fetcher.getJson(apiRequestObj, `${projectId}/processingSteps/${stepId}`, "DELETE", null).then(r => {
      return r;
    });
  }
  saveProjectStepOrder(projectId, stepType, order, apiRequestObj) {
    return this.fetcher.getJson(apiRequestObj, `${projectId}/processingSteps/order?stepType=${stepType.toLowerCase()}`, "PUT", order).then(r => {
      return r;
    });
  }

  getAskiaSurveys(activeOnly) {
    var qry = activeOnly ? "?active=true" : "";
    return this.fetcher.getJson(API_REQUESTS.SURVEYS, qry, "GET", null).then(r => {
      return r.surveys;
    });
  }
  getAskiaSurveyDefinition(taskId) {
    return this.fetcher.getJson(API_REQUESTS.ASKIA_SURVEYS, `${taskId}/definition`, "GET", null).then(r => {
      return r;
    });
  }
  updateAskiaSurveyMetadata(surveyId, surveyMetadataEntry) {
    return this.fetcher.getJson(API_REQUESTS.ASKIA_SURVEYS, `${surveyId}/metadata`, "PATCH", surveyMetadataEntry).then(r => {
      return r;
    });
  }
  syncAllAskiaSurveys() {
    return this.fetcher.getJson(API_REQUESTS.SURVEYS, `sync`, "POST", null);
  }
  syncAskiaSurvey(surveyId, force) {
    var qry = force ? "?force=true" : "";
    return this.fetcher.getJson(API_REQUESTS.SURVEYS, `${surveyId}/sync${qry}`, "POST", null);
  }
  getAskiaSurvey(surveyId) {
    return this.fetcher.getJson(API_REQUESTS.SURVEYS, surveyId, "GET", null).then(r => {
      return r.survey;
    });
  }
  editAskiaSurvey(surveyId, survey) {
    return this.fetcher.getJson(API_REQUESTS.SURVEYS, surveyId, "POST", survey).then(r => {
      return r.survey;
    });
  }

  addUserToKeyStaff(studyId, user) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/addUser`, "POST", user, "application/json").then(r => {
      return r.sampleFile;
    });
  }

  setUpConnectView(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/connect`, "POST");
  }

  // ////////////////////
  // SAMPLE FILE
  // ////////////////////

  getSampleFiles(studyId, waveId, stateFilter) {
    var route = waveId ? `${studyId}/waves/${waveId}/samplefiles` : `${studyId}/samplefiles`;
    var query = stateFilter ? `?state=${encodeURIComponent(stateFilter)}` : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${route}${query}`, "GET").then(r => {
      return r.sampleFiles;
    });
  }

  deleteSampleFileById(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}`, "DELETE");
  }
  getSampleFileById(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}`, "GET").then(r => {
      return r.sampleFile;
    });
  }
  getSampleFileCountries(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/countries`, "GET").then(r => {
      return r.countries;
    });
  }

  uploadSampleFile(
    studyId,
    file,
    name,
    delimiter,
    waveId,
    daysInService,
    daysToCutoff,
    contactMethods,
    tags,
    copyCalcsFrom,
    privacyPolicy,
    helpDoc,
    sampleFileType
  ) {
    var form = new FormData();

    form.append("Name", name);
    form.append("CsvDelimiter", delimiter);
    form.append("WaveId", waveId);
    form.append("DaysInService", daysInService);
    form.append("DaysToSurveyCutoff", daysToCutoff);
    form.append("PrivacyPolicy", privacyPolicy);
    form.append("SurveyHelp", helpDoc);
    form.append("SampleFileType", sampleFileType);
    contactMethods?.forEach((c, i) => {
      form.append(`ContactMethods[${i}]`, c);
    });
    tags?.forEach((t, i) => {
      form.append(`Tags[${i}]`, t);
    });
    form.append("SampleFile", file);

    if (copyCalcsFrom != null) form.append("CopyCalcsFrom", copyCalcsFrom);

    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/upload`, "POST", form, null).then(r => {
      return r.uploadResult;
    });
  }
  uploadCustomDropdowns(studyId, file) {
    var form = new FormData();
    form.append("File", file);
    form.append("StudyId", studyId);
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `customDropDown/study/${studyId}/upload`, "POST", form, null).then(r => {
      return r.uploadResult;
    });
  }
  downloadCustomDropdownTemplate() {
    return this.fetcher
      .getJson(API_REQUESTS.CURATIONS, `customDropDown/downloadTemplate`, "GET", null, "text/csv")
      .then(r => URL.createObjectURL(new Blob([r], { type: "text/csv" })))
      .then(url => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "custom_dropdown_template.csv");
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  getDropdownsDetails(studyId) {
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `customDropDown/study/${studyId}`, "GET", null, null).then(r => {
      return r;
    });
  }
  remodelSampleFile(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/remodel`, "POST").then(r => {
      return r.status;
    });
  }

  setFeatureMatrixVersion(studyId, sampleFileId, versionId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/set-featurematrix-version/${versionId}`, "POST", null);
  }

  validateSampleFile(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/validate`, "POST").then(r => {
      return r;
    });
  }
  verifySampleFile(studyId, sampleFileId, previewResults) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/verify`, "POST", { preview: previewResults }).then(r => {
      return r.job;
    });
  }
  getSampleFileVerificationStatus(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/verify`, "GET").then(r => {
      return r.job;
    });
  }
  processSampleFile(studyId, sampleFileId, processOptions) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/process`, "POST", processOptions).then(r => {
      return r.id;
    });
  }

  finalizeSampleFile(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/finalize`, "POST").then(r => {
      return r.status;
    });
  }

  queueSampleFileInvitations(studyId, sampleFileId, releaseId) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/queue`, "POST", { inviteReleaseId: releaseId })
      .then(r => {
        return r.id;
      });
  }
  getSampleFileVerificationCounts(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/verification`, "GET").then(r => {
      return r.verification;
    });
  }

  getSampleFileColumns(studyId, sampleFileId, source) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/verification/columns`, "POST", { source: source })
      .then(r => {
        return r.columns;
      });
  }
  getSampleFileColumnsWithJson(studyId, sampleFileId, source) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/verification/columnsWithJson`, "POST", { source: source })
      .then(r => {
        return r.columns;
      });
  }

  getSampleFileFrequencies(studyId, sampleFileId, source, columns, filter) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/verification/customcounts`, "POST", {
        source: source,
        sampleFileId: sampleFileId,
        filter: filter,
        columns: columns
      })
      .then(r => {
        return r.counts;
      });
  }

  getJobMetrics(jobId) {
    return this.fetcher.getJson(API_REQUESTS.JOBS, encodeURI(jobId) + "/metrics", "GET").then(r => {
      return r.metrics;
    });
  }

  // eslint-disable-next-line no-unused-vars
  setSurveyId(studyId, surveyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/survey/${surveyId}/update`, "GET").then(r => {
      return r;
    });
  }

  getStudyKeyStaff(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/users/`, "GET").then(r => {
      return r.userStudyPermissions;
    });
  }

  getAllUsersAndRoles(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/allusersandroles/`, "GET").then(r => {
      return r;
    });
  }

  getProjectKeyStaff(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/users/`, "GET").then(r => {
      return r.userProjectPermissions;
    });
  }

  getAllProjectUsersAndRoles(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/allusersandroles/`, "GET").then(r => {
      return r;
    });
  }

  setUserStudyPermissions(studyId, userId, roleIds) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/users/${userId}`, "POST", { permissions: roleIds }).then(r => {
      return r.userStudyPermissions;
    });
  }

  setUserProjectPermissions(projectId, userId, roleIds) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/users/${userId}`, "POST", { permissions: roleIds }).then(r => {
      return r.userProjectPermissions;
    });
  }

  setUpProjectConnectView(projectId) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT, `${projectId}/connect`, "POST");
  }

  getCurations() {
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, "", "GET").then(r => {
      return r.curations;
    });
  }
  getCurationVersions(curationId) {
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `${curationId}/versions`, "GET").then(r => {
      return r.versions;
    });
  }
  getCurationVersionTables(curationId, versionId) {
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `${curationId}/versions/${versionId}/tables`, "GET").then(r => {
      return r.tables;
    });
  }

  getCurationVersionTableData(curationId, versionId, tableId) {
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `${curationId}/versions/${versionId}/tables/${tableId}`, "GET").then(r => {
      return r.data;
    });
  }
  exportPhoneForCountry(version, country, excel) {
    var contentType = excel ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : null;
    var url = `phone/versions/${version}`;
    if (country) url += `/countries/${country}`;
    url += "/export";

    return this.fetcher
      .getJson(API_REQUESTS.CURATIONS, url, "POST", null, contentType, true)
      .then(r => {
        return r.blob();
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        var ext = excel === true ? "xlsx" : "json";
        var suffix = country ? `_${country}` : "";
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `phone${suffix}.${ext}`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  exportVehicleForCountry(version, country, excel) {
    var contentType = excel ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : null;
    var url = `vehicle/versions/${version}`;
    if (country) url += `/countries/${country}`;
    url += "/export";
    return this.fetcher
      .getJson(API_REQUESTS.CURATIONS, url, "POST", null, contentType, true)
      .then(r => {
        return r.blob();
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        var ext = excel === true ? "xlsx" : "json";
        var suffix = country ? `_${country}` : "";
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `vehicle${suffix}.${ext}`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  getPhoneCountries(version) {
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `phone/versions/${version}/countries`, "GET").then(r => {
      return r.countries;
    });
  }
  getPhoneCarriers(version, country, lang) {
    var qry = this.getCountryLangQuery(country, lang);
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `phone/versions/${version}/carriers${qry}`, "GET").then(r => {
      return r.carriers;
    });
  }
  getCountryLangQuery(country, lang) {
    var qry = country ? `country=${encodeURIComponent(country)}` : "";
    if (lang) {
      if (qry) qry += "&";
      qry += `language=${encodeURIComponent(lang)}`;
    }
    if (qry) qry = "?" + qry;
    return qry;
  }
  getPhoneBrands(version, country, lang) {
    var qry = this.getCountryLangQuery(country, lang);

    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `phone/versions/${version}/brands${qry}`, "GET").then(r => {
      return r.brands;
    });
  }
  getPhoneModelsForMake(version, brand, country, lang) {
    var qry = this.getCountryLangQuery(country, lang);
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `phone/versions/${version}/brands/${brand}/models${qry}`, "GET").then(r => {
      return r.models;
    });
  }
  getVehicleCountries(version) {
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `vehicle/versions/${version}/countries`, "GET").then(r => {
      return r.countries;
    });
  }
  getVehicleMakes(version, country, lang) {
    var qry = this.getCountryLangQuery(country, lang);
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `vehicle/versions/${version}/makes${qry}`, "GET").then(r => {
      return r.makes;
    });
  }
  getVehicleModelsForMake(version, make, country, lang) {
    var qry = this.getCountryLangQuery(country, lang);

    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `vehicle/versions/${version}/makes/${make}/models${qry}`, "GET").then(r => {
      return r.models;
    });
  }
  uploadVehicleVersionToBigQuery(version) {
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `vehicle/versions/${version}/set-bq-current-version`, "POST").then(r => {
      return r;
    });
  }
  uploadPhoneVersionToBigQuery(version) {
    return this.fetcher.getJson(API_REQUESTS.CURATIONS, `phone/versions/${version}/set-bq-current-version`, "POST").then(r => {
      return r;
    });
  }

  // ////////////////////
  // LOCALIZATION
  // ////////////////////

  getTimezoneCountry(tz) {
    return this.fetcher.getJson(API_REQUESTS.LOCALIZATION, `timezone-country`, "POST", { timezone: tz }).then(r => {
      return r;
    });
  }

  getAllCountryTimezones(countryList, currentTimezone) {
    return this.fetcher
      .getJson(API_REQUESTS.LOCALIZATION, `timezones/all-countries`, "Post", { timezone: currentTimezone, countries: countryList })
      .then(r => {
        return r;
      });
  }
  getCountryTimezones(country) {
    return this.fetcher.getJson(API_REQUESTS.LOCALIZATION, `countries/${country}/timezones`, "GET", null).then(r => {
      return r;
    });
  }
  getCountryTimezoneValidation(countryIsos, startDt, timezone, businessStartTimeStr, businessEndTimeStr, sendStartTimeStr, sendEndTimeStr) {
    return this.fetcher
      .getJson(API_REQUESTS.LOCALIZATION, `timezone-validation`, "POST", {
        countries: countryIsos,
        sendFromTimezone: timezone,
        date: startDt,
        businessHoursStartTime: businessStartTimeStr,
        businessHoursEndTime: businessEndTimeStr,
        sendStartTime: sendStartTimeStr,
        sendEndTime: sendEndTimeStr
      })
      .then(r => {
        return r;
      });
  }

  // ////////////////////
  // WAVE
  // ////////////////////

  getWaves(studyId, stateFilter) {
    var query = stateFilter ? `?state=${encodeURIComponent(stateFilter)}` : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves${query}`, "GET").then(r => {
      return r.waves;
    });
  }
  getWavesDetailed(studyId, stateFilter) {
    var query = stateFilter ? `?state=${encodeURIComponent(stateFilter)}` : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/detailed${query}`, "GET").then(r => {
      return r.wavesDetailed;
    });
  }
  getWave(studyId, waveId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/${waveId}`, "GET").then(r => {
      return r.wave;
    });
  }
  getOpenWaves(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/open`, "GET").then(r => {
      return r.waves;
    });
  }
  createWave(studyId, request) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/create`, "POST", request).then(r => {
      return r.wave;
    });
  }
  deleteWave(studyId, waveId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/${waveId}`, "DELETE").then(r => {
      return r.updateTime;
    });
  }
  closeWave(studyId, waveId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/${waveId}/close`, "PUT").then(r => {
      return r.updateTime;
    });
  }
  openWave(studyId, waveId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/${waveId}/open`, "PUT").then(r => {
      return r.updateTime;
    });
  }
  finalizeWave(studyId, waveId, projectId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/${waveId}/projectId/${projectId}/finalize`, "PUT").then(r => {
      return r.updateTime;
    });
  }
  GetFinalizationStatus(studyId, waveId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/${waveId}/finalization`, "GET").then(r => {
      return r.finalizationDetails;
    });
  }
  GetProjectsContainingStudyId(studyId, waveId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/waves/${waveId}/projects`, "GET").then(r => {
      return r.projects;
    });
  }

  // ////////////////////
  // INVITATION TEMPLATES
  // ////////////////////

  getInvitationTemplates(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates`, "GET").then(r => {
      return r.invitationTemplates;
    });
  }
  getInvitationTemplateById(studyId, templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}`, "GET").then(r => {
      return r.invitationTemplate;
    });
  }
  getPlaintextFromHtml(html) {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `html-to-text`, "POST", html).then(r => {
      return r.text;
    });
  }
  getEmailHtmlPlaintext(studyId, templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/html-to-text`, "GET").then(r => {
      return r.text;
    });
  }

  renameInvitationTemplate(studyId, templateId, name) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/rename`, "POST", { name: name }).then(r => {
      return r.updateTime;
    });
  }
  updateInvitationTemplateEmailSubject(studyId, templateId, subject) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/update-subject`, "POST", { subject: subject })
      .then(r => {
        return r.updateTime;
      });
  }
  revertInvitationTemplateToLastPublish(studyId, templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/revert-to-latest`, "POST").then(r => {
      return r.template;
    });
  }
  updateInvitationTemplateText(studyId, templateId, text) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/update-template-text`, "POST", {
        text: text
      })
      .then(r => {
        return r.updateTime;
      });
  }
  updateInvitationTemplate(studyId, templateId, components, template, mjml) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/updatetemplate`, "POST", {
        components: components,
        template: template,
        mjml: mjml
      })
      .then(r => {
        return r.updateTime;
      });
  }

  deleteInvitationTemplate(studyId, templateId, typedName) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}`, "DELETE", { typedName: typedName }).then(r => {
      return r.updateTime;
    });
  }
  copyInvitationTemplate(studyId, templateId, name) {
    var query = name ? `?name=${encodeURIComponent(name)}` : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/copy${query}`, "POST").then(r => {
      return r.template;
    });
  }
  createInvitationTemplate(studyId, channel, name, baseTemplate, templateType, subject) {
    var inv = {
      name: name,
      subject: subject,
      channel: channel,
      baseTemplateId: baseTemplate,
      templateType: templateType
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates`, "POST", inv).then(r => {
      return r.id;
    });
  }

  // ////////////////////
  // STUDY CALENDAR
  // ////////////////////
  getStudyCalendarEvents(studyId, dat) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/calendar`, "POST", dat).then(r => {
      return r.events;
    });
  }

  // ////////////////////
  // INVITATION PUBLICATIONS
  // ////////////////////

  getInvitationTemplatePublications(studyId, templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/publications`, "GET").then(r => {
      return r.publications;
    });
  }
  getInvitationTemplatePublication(studyId, templateId, id) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/publications/${id}`, "GET").then(r => {
      return r.publication;
    });
  }
  saveTemplateReleaseAsStudyTemplate(studyId, templateId, publicationId, studyTemplateId, name, channel) {
    var dat = {
      studyTemplateName: name,
      studyTemplateChannel: channel,
      studyTemplateId: studyTemplateId
    };

    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/publications/${publicationId}/save-as-study-template`, "POST", dat)
      .then(r => {
        return r.studyTemplateInvitation;
      });
  }
  publishInvitationTemplate(studyId, templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitationTemplates/${templateId}/publications`, "POST").then(r => {
      return r.publication;
    });
  }

  // ////////////////////
  // EMAIL
  // ////////////////////
  sendEmail(to, from, subject, html, text, autoGenerateText) {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `send`, "POST", {
      autoGenerateText: autoGenerateText,
      Message: {
        To: to,
        From: from,
        Subject: subject,
        Html: html,
        Text: text
      }
    });
  }

  sendStudyEmail(studyId, userEmail, sendingAccountId, format, subject, templateId, data, autoGenerateText) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/email`, "POST", {
      Format: format,
      TemplateId: templateId,
      SendingAccountId: sendingAccountId,
      autoGenerateText: autoGenerateText,
      Message: {
        To: userEmail,
        Subject: subject
      },
      Data: data
    });
  }

  // ////////////////////
  // INVITATION QUEUE
  // ////////////////////
  getAllInvitationQueueErrors(unresolved) {
    var query = unresolved ? "?unresolved=true" : "";
    return this.fetcher.getJson(API_REQUESTS.INVITATION_QUEUE, `run-log-errors${query}`, "GET").then(r => {
      return r;
    });
  }
  getInvitationQueueRunLog(studyId, campaignId) {
    var query = campaignId ? `?campaignId=${encodeURIComponent(campaignId)}` : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitation-queue/run-log${query}`, "GET").then(r => {
      return r;
    });
  }
  getInvitationQueueErrorLog(studyId, campaignId) {
    var query = campaignId ? `?campaignId=${encodeURIComponent(campaignId)}` : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitation-queue/error-log${query}`, "GET").then(r => {
      return r;
    });
  }
  getApprovedIsps(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitation-queue/approved-domains`, "GET").then(r => {
      return r;
    });
  }
  getInvitationQueueStatusCounts(studyId, campaignId, staging) {
    var query = campaignId ? `campaignId=${encodeURIComponent(campaignId)}` : "";
    if (staging) {
      if (query.length > 0) query += "&";
      query = query + "staging=true";
    }
    if (query.length > 0) query = "?" + query;

    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitation-queue/status-count${query}`, "GET").then(r => {
      return r;
    });
  }
  getInvitationQueueScheduledAndSent(studyId, campaignId, timezone, staging, granularity, addEvents, waveId) {
    var query = timezone ? `?timezone=${encodeURIComponent(timezone)}` : "";
    if (campaignId) {
      query += query === "" ? "?" : "&";
      query += `campaignId=${encodeURIComponent(campaignId)}`;
    }
    if (staging) {
      query += query === "" ? "?" : "&";
      query += `staging=true`;
    }
    if (granularity) {
      query += query === "" ? "?" : "&";
      query += `granularity=${encodeURIComponent(granularity)}`;
    }
    if (addEvents) {
      query += query === "" ? "?" : "&";
      query += `addEvents=true`;
    }
    if (waveId) {
      query += query === "" ? "?" : "&";
      query += `waveId=${encodeURIComponent(waveId)}`;
    }
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitation-queue/scheduled-and-sent${query}`, "GET").then(r => {
      return r;
    });
  }
  // getInvitationQueueSent(studyId) {
  //   return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitation-queue/sent`, "GET").then(r => {
  //     return r;
  //   });
  // }
  // getInvitationQueueScheduled(studyId) {
  //   return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/invitation-queue/scheduled`, "GET").then(r => {
  //     return r;
  //   });
  // }

  // ////////////////////
  // STUDY MESSAGES
  // ////////////////////

  getSupportEmailMessages(studyId, domain) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/messages/${domain}`, "GET").then(r => {
      return r.messages;
    });
  }
  getSupportEmailMessage(studyId, domain, msgId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/messages/${domain}/${msgId}`, "GET").then(r => {
      return r.message;
    });
  }
  replyToSupportEmailMessage(studyId, domain, messageId, message) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/messages/${domain}/${messageId}/reply`, "POST", { bodyText: message }).then(r => {
      return r.message;
    });
  }
  GetSupportEmailMessageAttachment(studyId, domain, messageId, attachmentId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/messages/${domain}/${messageId}/attachments/${attachmentId}`, "GET").then(r => {
      return r.attachment;
    });
  }
  markSupportMessageAsRead(studyId, domain, messageId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/messages/${domain}/${messageId}/read`, "POST").then(r => {
      return r.ok;
    });
  }

  // ////////////////////
  // EMAIL DOMAINS
  // ////////////////////

  getEmailRoutes() {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `routes`, "GET").then(r => {
      return r.routes;
    });
  }

  getEmailDomainStatistics(domain, aggregate) {
    // aggregate: total, countries, providers, devices

    return this.fetcher.getJson(API_REQUESTS.EMAIL, `domains/${domain}/${aggregate}`, "GET").then(r => {
      return r.stats;
    });
  }

  getEmailDomains() {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `domains`, "GET").then(r => {
      return r.domains;
    });
  }
  getEmailDomain(domain) {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `domains/${domain}`, "GET").then(r => {
      return r.domain;
    });
  }
  getEmailDomainValidation(domain) {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `domains/${domain}/get-validation`, "GET").then(r => {
      return r.domainValidation;
    });
  }
  validateEmailDomain(domain) {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `domains/${domain}/validate`, "POST").then(r => {
      return r.valid;
    });
  }
  addEmailDomainHttpsTracking(domain, add) {
    var path = add ? "set-https" : "set-http";
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `domains/${domain}/${path}`, "POST").then(r => {
      return r.added;
    });
  }
  addEmailDomainReplyWebhook(domain) {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `domains/${domain}/add-reply-webhook`, "POST").then(r => {
      return r.added;
    });
  }
  addEmailDomainEventWebhooks(domain) {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `domains/${domain}/add-event-webhooks`, "POST").then(r => {
      return r.added;
    });
  }
  addEmailDomainDmarc(domain) {
    return this.fetcher.getJson(API_REQUESTS.EMAIL, `domains/${domain}/add-dmarc`, "POST").then(r => {
      return r.added;
    });
  }

  getStudyEmailDomains(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/domains`, "GET").then(r => {
      return r.domains;
    });
  }
  getStudyEmailDomainSupportAccounts(studyId, domainId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/domains/${domainId}/support`, "GET").then(r => {
      return r.supportAccounts;
    });
  }
  createStudyDomainSupportAccount(studyId, domainId, accountEmail) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/domains/${domainId}/support`, "POST", { email: accountEmail }).then(r => {
      return r.supportAccount;
    });
  }
  deleteStudyDomainSupportAccount(studyId, domainId, accountEmail) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/domains/${domainId}/support/${accountEmail}`, "DELETE").then(r => {
      return r.updateTime;
    });
  }

  // ////////////////////
  // FIELDWORK FAILUREES
  // ////////////////////
  getFieldworkFailures(studyId, campaignIds, keyColumn, groupColumn, daysBack, waveId, brandIds, modelIds, fuelTypeIds, modelYearIds, countriesIso) {
    var data = {
      waveId: waveId,
      campaignIds: campaignIds,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      modelYearIds: modelYearIds,
      countriesIso: countriesIso,
      key: keyColumn,
      group: groupColumn,
      daysBack: daysBack
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/failures`, "POST", data).then(r => {
      return r.failures;
    });
  }
  getFieldworkFailuresByDate(
    studyId,
    campaignIds,
    groupColumn,
    daysBack,
    timezone,
    waveId,
    brandIds,
    modelIds,
    fuelTypeIds,
    modelYearIds,
    countriesIso
  ) {
    var data = {
      waveId: waveId,
      campaignIds: campaignIds,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      modelYearIds: modelYearIds,
      countriesIso: countriesIso,
      group: groupColumn,
      daysBack: daysBack,
      timezone: timezone
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/failures-by-date`, "POST", data).then(r => {
      return r.failures;
    });
  }
  // ////////////////////
  // FIELDWORK
  // ////////////////////
  getRespondentFieldworkActivity(studyId, respondentId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/respondent/${respondentId}`, "POST").then(r => {
      return r.respondent;
    });
  }

  GetInvitationOpenedClientInfoAsync(studyId, campaignIds, column, waveId, brandIds, modelIds, fuelTypeIds, modelYearIds, countriesIso) {
    var data = {
      waveId: waveId,
      campaignIds: campaignIds,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      modelYearIds: modelYearIds,
      countriesIso: countriesIso,
      clientColumn: column
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/client`, "POST", data).then(r => {
      return r.clientInfo;
    });
  }

  getEvents(studyId, campaignIds, waveId, brandIds, modelIds, fuelTypeIds, modelYearIds, countriesIso) {
    var data = {
      waveId: waveId,
      campaignIds: campaignIds,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      countriesIso: countriesIso,
      modelYearIds: modelYearIds
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/events`, "POST", data).then(r => {
      return r.events;
    });
  }
  getPausedEsps(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/paused-esps`, "POST").then(r => {
      return r.pausedEsps;
    });
  }
  getEventsOverTime(studyId, campaignIds, granularity, lastXDays, waveId, brandIds, modelIds, fuelTypeIds, modelYearIds, countriesIso) {
    var data = {
      campaignIds: campaignIds,
      waveId: waveId,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      modelYearIds: modelYearIds,
      countriesIso: countriesIso,
      daysBack: lastXDays,
      timezone: TIMEZONE_OFFEST,
      granularity: granularity
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/events-over-time`, "POST", data).then(r => {
      return r.events;
    });
  }
  getFieldworkSenderIpCounts(studyId, campaignIds, timeSeries, lastXDays, waveId, brandIds, modelIds, fuelTypeIds, modelYearIds, countriesIso) {
    var data = {
      campaignIds: campaignIds,
      waveId: waveId,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      modelYearIds: modelYearIds,
      countriesIso: countriesIso,
      daysBack: lastXDays,
      timezone: TIMEZONE_OFFEST,
      timeSeries: timeSeries
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/sender-ip`, "POST", data).then(r => {
      return r.sendingIpCounts;
    });
  }
  getFieldworkSenderIpEspCounts(studyId, campaignIds, timeSeries, lastXDays, waveId, brandIds, modelIds, fuelTypeIds, modelYearIds, countriesIso) {
    var data = {
      campaignIds: campaignIds,
      waveId: waveId,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      modelYearIds: modelYearIds,
      countriesIso: countriesIso,
      daysBack: lastXDays,
      timezone: TIMEZONE_OFFEST,
      timeSeries: timeSeries
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/sender-ipesp`, "POST", data).then(r => {
      return r.sendingIpEspCounts;
    });
  }

  getPostSurveyCounts(studyId, campaignIds, waveId, brandIds, modelIds, fuelTypeIds, modelYearIds, countriesIso) {
    var data = {
      campaignIds: campaignIds,
      waveId: waveId,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      modelYearIds: modelYearIds,
      countriesIso: countriesIso
    };

    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/post-survey`, "POST", data).then(r => {
      return r.counts;
    });
  }

  getFieldworkMetricsOverTime(studyId, campaignIds, timeSeries, lastXDays, waveId, brandIds, modelIds, fuelTypeIds, modelYearIds, countriesIso) {
    var data = {
      campaignIds: campaignIds,
      waveId: waveId,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      modelYearIds: modelYearIds,
      countriesIso: countriesIso,
      daysBack: lastXDays,
      timezone: TIMEZONE_OFFEST,
      timeSeries: timeSeries
    };

    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/metrics-over-time`, "POST", data).then(r => {
      return r.completes;
    });
  }
  getGroupedFieldworkMetrics(studyId, campaignIds, lastXDays, groupby, waveId, brandIds, modelIds, fuelTypeIds, modelYearIds, countriesIso) {
    var data = {
      campaignIds: campaignIds,
      waveId: waveId,
      daysBack: lastXDays,
      timezone: TIMEZONE_OFFEST,
      group: groupby,
      brandIds: brandIds,
      modelIds: modelIds,
      fuelTypeIds: fuelTypeIds,
      modelYearIds: modelYearIds,
      countriesIso: countriesIso
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/grouped`, "POST", data).then(r => {
      return r.metrics;
    });
  }

  getSampleFileCreateMetadata(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/create-metadata`, "GET").then(r => {
      return r;
    });
  }

  getBrands(studyId, waveId, campaignIds) {
    var data = {
      waveId: waveId,
      campaignIds: campaignIds
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/brands`, "POST", data).then(r => {
      return r.brands;
    });
  }

  getModels(studyId, waveId, campaignIds, brandIds) {
    var data = {
      waveId: waveId,
      campaignIds: campaignIds,
      brandIds: brandIds
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/models`, "POST", data).then(r => {
      return r.models;
    });
  }

  getFuelTypes(studyId, waveId, campaignIds, brandIds, modelIds) {
    var data = {
      waveId: waveId,
      campaignIds: campaignIds,
      brandIds: brandIds,
      modelIds: modelIds
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/fuel-types`, "POST", data).then(r => {
      return r.fuelTypes;
    });
  }

  getModelYears(studyId, waveId, campaignIds, brandIds, modelIds) {
    var data = {
      waveId: waveId,
      campaignIds: campaignIds,
      brandIds: brandIds,
      modelIds: modelIds
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/model-years`, "POST", data).then(r => {
      return r.modelYears;
    });
  }

  getCountries(studyId, waveId, campaignIds) {
    var data = {
      waveId: waveId,
      campaignIds: campaignIds
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/fieldwork/countries`, "POST", data).then(r => {
      return r.countries;
    });
  }

  // ////////////////////
  // STUDY PRIVACY POLICIES
  // ////////////////////
  getStudyHtmlDocuments(studyId, documentType, publishedOnly) {
    var qry = documentType ? `?documentType=${encodeURIComponent(documentType)}` : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/documents${qry}`, "GET").then(r => {
      if (publishedOnly) return r.documents.filter(d => d.publishedLanguages?.length > 0);

      return r.documents;
    });
  }
  getStudyHtmlDocumentLanguages(studyId, docId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/documents/${docId}/languages`, "GET").then(r => {
      return r.languageDocuments;
    });
  }
  getStudyHtmlDocumentLanguage(studyId, docId, languageId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/documents/${docId}/languages/${languageId}`, "GET").then(r => {
      return r.languageDocument;
    });
  }
  updateStudyHtmlDocumentLanguage(studyId, docId, languageId, components, html, css) {
    var dat = {
      components: components,
      template: html,
      css
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/documents/${docId}/languages/${languageId}/update`, "POST", dat).then(r => {
      return r.languageDocument;
    });
  }

  publishStudyHtmlDocumentLanguage(studyId, docId, languageId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/documents/${docId}/languages/${languageId}/publish`, "POST", null).then(r => {
      return r.publication;
    });
  }
  createStudyHtmlDocument(studyId, name, docType) {
    var dat = {
      name: name?.trim(),
      documentType: docType
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/documents`, "POST", dat).then(r => {
      return r.id;
    });
  }
  addStudyHtmlDocumentLanguage(studyId, docId, language) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/documents/${docId}/languages/${language}`, "POST").then(r => {
      return r.template;
    });
  }
  renameStudyHtmlDocument(studyId, docId, name) {
    var dat = {
      name: name?.trim()
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/documents/${docId}/rename`, "POST", dat).then(r => {
      return r.updateTime;
    });
  }
  getStudyHtmlDocumentAllowedLanguages(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/languages`, "GET", null).then(r => {
      return r.languages;
    });
  }

  // ////////////////////
  // PRIVACY POLICIES
  // ////////////////////

  getPrivacyPolicies() {
    return this.fetcher.getJson(API_REQUESTS.PRIVACY_POLICIES, "", "GET").then(r => {
      return r.privacyPolicies;
    });
  }

  // createPrivacyPolicy(studyId, key, value, url) {
  //   var pol = {
  //     key: key,
  //     value: value,
  //     url: url
  //   };
  //   return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/privacyPolicies`, "POST", pol).then(r => {
  //     return r.id;
  //   });
  // }
  // updatePrivacyPolicy(studyId, policyId, key, value, url) {
  //   return this.fetcher
  //     .getJson(API_REQUESTS.STUDY, `${studyId}/privacyPolicies/${policyId}/updatePolicy`, "POST", {
  //       id: policyId,
  //       key: key,
  //       value: value,
  //       url: url
  //     })
  //     .then(r => {
  //       return r.updateTime;
  //     });
  // }
  // deletePrivacyPolicy(studyId, policyId) {
  //   return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/privacyPolicies/${policyId}`, "DELETE").then(r => {
  //     return r.updateTime;
  //   });
  // }

  // ////////////////////
  // CAMPAIGNS
  // ////////////////////
  getCampaigns(studyId, waveId, stateFilter) {
    var route = waveId ? `${studyId}/waves/${waveId}/campaigns` : `${studyId}/campaigns`;
    var query = stateFilter ? `?state=${encodeURIComponent(stateFilter)}` : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${route}${query}`, "GET").then(r => {
      return r.campaigns;
    });
  }

  getAllCampaigns(state) {
    var st = state || "ACTIVE";
    return this.fetcher.getJson(API_REQUESTS.CAMPAIGNS, `all/${encodeURIComponent(st)}`, "GET").then(r => {
      return r.campaigns;
    });
  }
  getCampaign(studyId, campaignId, detailed) {
    var query = detailed ? "?getDetailed=true" : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/${campaignId}${query}`, "GET").then(r => {
      return r.campaign;
    });
  }
  deleteCampaign(studyId, campaignId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/${campaignId}`, "DELETE").then(r => {
      return r.updateTime;
    });
  }
  validateCampaign(studyId, campaign) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/campaigns`, "POST", {
        campaign: campaign
      })
      .then(r => {
        return r.id;
      });
  }
  createCampaign(studyId, campaign, setSampleFilePrimary) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/campaigns`, "POST", {
        SetSampleFilePrimaryCampaign: setSampleFilePrimary || false,
        campaign: campaign
      })
      .then(r => {
        return r;
      });
  }
  createInterceptCampaign(studyId, waveId, currentCampaignId) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/create-intercept`, "POST", { waveId: waveId, currentCampaignId: currentCampaignId })
      .then(r => {
        return r;
      });
  }

  processCampaign(studyId, campaignId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/${campaignId}/process`, "POST").then(r => {
      return r.jobId;
    });
  }
  cumulateCampaign(studyId, campaignId, activate) {
    var query = activate ? "?activate=true" : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/${campaignId}/cumulate${query}`, "POST").then(r => {
      return r;
    });
  }
  activateContactlessCampaign(studyId, campaignId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/${campaignId}/activate-contactless`, "POST").then(r => {
      return r;
    });
  }
  activateCampaign(studyId, campaignId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/${campaignId}/activate`, "POST").then(r => {
      return r;
    });
  }
  deactivateCampaign(studyId, campaignId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/${campaignId}/deactivate`, "POST").then(r => {
      return r.state;
    });
  }
  pauseCampaign(studyId, campaignId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/${campaignId}/pause`, "POST").then(r => {
      return r.state;
    });
  }
  unpauseCampaign(studyId, campaignId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigns/${campaignId}/unpause`, "POST").then(r => {
      return r.state;
    });
  }
  getCampaignTemplates(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigntemplates`, "GET").then(r => {
      return r;
    });
  }
  getCampaignTemplate(studyId, templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigntemplates/${templateId}`, "GET").then(r => {
      return r;
    });
  }
  deleteCampaignTemplate(studyId, templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigntemplates/${templateId}`, "DELETE").then(r => {
      return r.updateTime;
    });
  }
  saveCampaignTemplate(studyId, campaignId, templateName) {
    var dat = {
      campaignId: campaignId,
      name: templateName
    };
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/campaigntemplates`, "POST", dat).then(r => {
      return r.updateTime;
    });
  }

  getSmsAccountBalanceFromSendingAccount(studyId, sendingAccountId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/get-sending-account-sms-balance/${sendingAccountId}`, "GET").then(r => {
      return r;
    });
  }
  getSmsAccountBalancesFromCalculation(studyId, sampleFileId, calculationId) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/get-calculation-sms-balances/${calculationId}`, "GET")
      .then(r => {
        return r;
      });
  }

  // ////////////////////
  // SENDING ACCOUNTS
  // ////////////////////

  getSendingAccounts(studyId, verifiedOnly) {
    var query = verifiedOnly ? "?verified=true" : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sendingAccounts${query}`, "GET").then(r => {
      return r;
    });
  }

  getActiveSendingAccounts(studyId, verifiedOnly) {
    var query = verifiedOnly ? "?verified=true" : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sendingAccounts/active${query}`, "GET").then(r => {
      return r;
    });
  }

  createSendingAccount(studyId, sender) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sendingAccounts/add`, "POST", sender).then(r => {
      return r.id;
    });
  }
  updateSendingAccount(studyId, sender) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sendingAccounts/update`, "POST", sender).then(r => {
      return r.id;
    });
  }
  sendSenderVerificationEmail(studyId, accountId, action) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sendingAccounts/verify/${action}`, "POST", accountId);
  }
  removeSendingAccount(studyId, accountId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sendingAccounts/${accountId}/remove`, "DELETE").then(r => {
      return r.updateTime;
    });
  }
  toggleSendingAccount(studyId, accountId, disable) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sendingAccounts/${accountId}/toggle`, "POST", { disable: disable }).then(r => {
      return r.updateTime;
    });
  }

  getSmsConfig(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sms`, "GET").then(r => {
      return r;
    });
  }

  createSmsConfig(studyId, baseUrl, apiKey) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/sms/add`, "POST", {
        baseUrl: baseUrl,
        apiKey: apiKey
      })
      .then(r => {
        return r.id;
      });
  }

  removeSmsConfig(studyId, accountId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sms/${accountId}/remove`, "DELETE").then(r => {
      return r.updateTime;
    });
  }

  getSharedAssets(collection) {
    return this.fetcher.getJson(API_REQUESTS.ASSETS, `${collection}`, "GET").then(r => {
      return r.assets;
    });
  }
  getValidatedDomains(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/domains/validated`, "GET").then(r => {
      return r;
    });
  }
  getStudySmsAccounts(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/smsAccounts`, "GET").then(r => {
      return r;
    });
  }
  getSmsAccountSenders(accountId) {
    return this.fetcher.getJson(API_REQUESTS.SMS_ACCOUNTS, `${accountId}/senders`, "GET").then(r => {
      return r;
    });
  }

  // ////////////////////
  // SURVEY ASSETS
  // ////////////////////
  getSurveyAsset(questionId, versionId, request) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/survey-api-test`, "POST", request);
  }

  // ////////////////////
  // IMAGE QUESTIONS
  // ////////////////////

  getImageQuestions() {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, "", "GET").then(r => {
      return r.questions;
    });
  }
  createImageQuestion(id, name, collectionId, surveyId) {
    return this.fetcher
      .getJson(API_REQUESTS.IMAGE_QUESTIONS, `create`, "POST", { id: id, name: name, collectionId: collectionId, surveyId: surveyId })
      .then(r => {
        return r.question;
      });
  }
  getImageQuestionSurveyCheckboxes(questionId) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/survey-checkboxes`, "GET").then(r => {
      return r.checkboxes;
    });
  }
  getImageQuestionVersions(questionId) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions`, "GET").then(r => {
      return r.versions;
    });
  }
  updateImageQuestionVersion(questionId, versionId, dat) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}`, "POST", dat).then(r => {
      return r.version;
    });
  }
  getImageQuestionViews(questionId, versionId) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/views`, "GET");
  }
  createImageQuestionView(questionId, versionId, view) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/views`, "PUT", view).then(r => {
      return r.view;
    });
  }
  updateImageQuestionView(questionId, versionId, view) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/views/${view.id}`, "POST", view).then(r => {
      return r.view;
    });
  }
  deleteImageQuestionView(questionId, versionId, viewId) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/views/${viewId}`, "DELETE").then(r => {
      return r.dateTime;
    });
  }
  getImageQuestionViewVariants(questionId, versionId, viewId) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/views/${viewId}/variants`, "GET");
  }
  updateImageQuestionViewVariant(questionId, versionId, viewId, variant) {
    return this.fetcher.getJson(
      API_REQUESTS.IMAGE_QUESTIONS,
      `${questionId}/versions/${versionId}/views/${viewId}/variants/${variant.id}`,
      "POST",
      variant
    );
  }
  createImageQuestionViewVariant(questionId, versionId, viewId, variant) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/views/${viewId}/variants`, "PUT", variant);
  }
  deleteImageQuestionViewVariant(questionId, versionId, viewId, variantId) {
    return this.fetcher
      .getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/views/${viewId}/variants/${variantId}`, "DELETE")
      .then(r => {
        return r.dateTime;
      });
  }

  getImageQuestionCheckboxMappings(questionId, versionId) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/checkboxmap`, "GET").then(r => {
      return r.maps;
    });
  }
  createImageQuestionCheckboxMapping(questionId, versionId, mapping) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/checkboxmap`, "PUT", mapping).then(r => {
      return r.map;
    });
  }
  updateImageQuestionCheckboxMapping(questionId, versionId, map) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/checkboxmap/${map.id}`, "POST", map).then(r => {
      return r.map;
    });
  }
  deleteImageQuestionCheckboxMapping(questionId, versionId, mapId) {
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/versions/${versionId}/checkboxmap/${mapId}`, "DELETE");
  }
  publishImageQuestion(questionId, versionId, versionName) {
    var data = { versionName: versionName };
    return this.fetcher.getJson(API_REQUESTS.IMAGE_QUESTIONS, `${questionId}/version/${versionId}/publish`, "POST", data).then(r => {
      return r;
    });
  }
  exportImageQuestionVersionToExcel(quesId, verId) {
    return this.fetcher
      .getJson(API_REQUESTS.IMAGE_QUESTIONS, `${quesId}/versions/${verId}/export`, "POST", null, undefined, true)
      .then(r => {
        if (r.status === 200) return r.blob();
        else throw Error("Error exporting data.");
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `ImageQuestion_${quesId}_${verId}.xlsx`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  // ////////////////////
  // COLLECTION ASSETS
  // ////////////////////

  getAssetCollections() {
    return this.fetcher.getJson(API_REQUESTS.ASSET_COLLECTIONS, "", "GET").then(r => {
      return r.collections;
    });
  }

  getAssetCollection(id) {
    return this.fetcher.getJson(API_REQUESTS.ASSET_COLLECTIONS, id, "GET").then(r => {
      return r.collection;
    });
  }

  createAssetCollection(c) {
    return this.fetcher.getJson(API_REQUESTS.ASSET_COLLECTIONS, `create`, "POST", c).then(r => {
      return r.collection;
    });
  }

  getCollectionAssets(collectionId) {
    return this.fetcher.getJson(API_REQUESTS.ASSET_COLLECTIONS, `${collectionId}/assets`, "GET");
  }
  getCollectionAsset(collection, assetId) {
    return this.fetcher.getJson(API_REQUESTS.ASSET_COLLECTIONS, `${collection}/${assetId}`, "GET", null);
  }
  // deleteCollectionAsset(collectionId, assetId) {
  //   return this.fetcher.getJson(API_REQUESTS.ASSET_COLLECTIONS, `${collectionId}/${assetId}`, "DELETE");
  // }
  deleteCollectionAssets(collectionId, assetIds) {
    return this.fetcher.getJson(API_REQUESTS.ASSET_COLLECTIONS, `${collectionId}/delete`, "DELETE", assetIds);
  }
  editAssetCollectionAsset(collectionId, assetId, dat) {
    return this.fetcher.getJson(API_REQUESTS.ASSET_COLLECTIONS, `${collectionId}/assets/${assetId}/edit`, "POST", dat);
  }
  uploadCollectionAssets(collectionId, files) {
    var t = this;
    if (files.length < 1) return Promise.reject();

    var form = new FormData();
    form.append("CollectionId", collectionId);
    files.forEach(f => {
      form.append("Files", f);
    });

    return t.fetcher.getJson(API_REQUESTS.ASSET_COLLECTIONS, `upload`, "POST", form, null);
  }
  // ////////////////////
  // STUDY ASSETS
  // ////////////////////
  getStudyAssets(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/assets`, "GET").then(r => {
      return r.assets;
    });
  }

  deleteStudyAsset(studyId, assetId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/assets/${assetId}`, "DELETE");
  }

  deleteStudyAssets(studyId, assetId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/assets/${assetId}`, "DELETE");
  }
  getStudyAsset(studyId, assetId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/assets/${assetId}`, "GET").then(r => {
      return r.asset;
    });
  }
  editStudyAsset(studyId, assetId, name, file) {
    var form = new FormData();
    form.append("Name", name);
    var t = this;
    if (file) form.append("File", file);

    return new Promise((resolve, reject) => {
      t.fetcher
        .getJson(API_REQUESTS.STUDY, `${studyId}/assets/${assetId}/update`, "POST", form, null)
        .then(r => {
          resolve(r.asset);
        })
        .catch(e => {
          reject(`${e.statusText}. ${e.customError}`);
        });
    });
  }

  uploadStudyAssets(studyId, files) {
    var t = this;
    if (files.length < 1) return Promise.reject();

    var form = new FormData();

    files.forEach(f => {
      form.append("Files", f);
    });
    return new Promise((resolve, reject) => {
      t.fetcher
        .getJson(API_REQUESTS.STUDY, `${studyId}/assets/upload`, "POST", form, null)
        .then(r => {
          resolve(r.assets);
        })
        .catch(e => {
          reject(`${e.statusText}. ${e.customError}`);
        });
    });
  }

  // ////////////////////
  // CALCULATIONS
  // ////////////////////

  getSampleFileCalculationLookupValues(studyId, sampleFileId, calculationId) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/calculations/${calculationId}/lookup-values`, "POST")
      .then(r => {
        return r.lookupValues;
      });
  }

  getSampleFileCalculations(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/calculations`, "GET").then(r => {
      return r.calculations;
    });
  }
  getSampleFileLookups(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/lookups`, "GET").then(r => {
      return r.lookups;
    });
  }
  getTemplateCalculationValues(studyId, calcTemplateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/calculationtemplates/${calcTemplateId}/values`, "GET").then(r => {
      return r.values;
    });
  }
  getSampleFileCalculation(studyId, sampleFileId, id) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/calculations/${id}`, "GET").then(r => {
      return r.calculation;
    });
  }

  createSampleFileLookupCalculation(studyId, sampleFileId, calc) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/calculations/create-lookup`, "POST", calc).then(r => {
      return r.calculation;
    });
  }
  createSampleFileCalculation(studyId, sampleFileId, calc) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/calculations/create`, "POST", calc).then(r => {
      return r.calculation;
    });
  }
  updateSampleFileLookupCalculation(studyId, sampleFileId, calculationId, calc) {
    var dat = {
      Name: calc.name,
      Description: calc.description,
      Lookup: calc.lookup,
      LookupValues: calc.lookupValues || []
    };
    return this.fetcher.getJson(
      API_REQUESTS.STUDY,
      `${studyId}/samplefiles/${sampleFileId}/calculations/${calculationId}/update-lookup`,
      "POST",
      dat
    );
  }
  // applySampleFileCalculations(studyId, sampleFileId) {
  //   return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/calculations/apply`, "POST").then(r => {
  //     return r;
  //   });
  // }
  deleteSampleFileCalculation(studyId, sampleFileId, id) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/calculations/${id}`, "DELETE");
  }
  updateSampleFileCalculation(studyId, sampleFileId, calculationId, calc) {
    var dat = {
      Name: calc.name,
      AllowNulls: calc.allowNulls,
      Description: calc.description,
      Definitions: calc.definitions || []
    };
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/calculations/${calculationId}/update-calculation`, "POST", dat)
      .then(r => {
        return r.calculation;
      });
  }
  updateSampleFileScrubCalculation(studyId, sampleFileId, calculationId, calc) {
    var dat = {
      Name: calc.name,
      AllowNulls: calc.allowNulls,
      Description: calc.description,
      Definitions: calc.definitions || []
    };
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/calculations/${calculationId}/update-scrub`, "POST", dat)
      .then(r => {
        return r.calculation;
      });
  }

  // ////////////////////
  // QUOTAS
  // ////////////////////

  createQuota(studyId, calc) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotas/create`, "POST", calc).then(r => {
      return r.quota;
    });
  }
  getQuota(studyId, quotaId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotas/${quotaId}`, "GET", null).then(w => {
      return w.quotas;
    });
  }
  getQuotas(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotas/all`, "GET", null).then(w => {
      return w.quotas;
    });
  }
  getSampleFileQuotas(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/quotas/all`, "GET", null).then(w => {
      return w.quotas;
    });
  }
  getQuotaDefinition(studyId, quotaId, definitionId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotas/${quotaId}/definitions/${definitionId}`, "GET", null).then(w => {
      return w.definition;
    });
  }
  updateQuota(studyId, quotaId, quota) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotas/${quotaId}/update`, "POST", quota).then(r => {
      return r.updateTime;
    });
  }
  duplicateQuota(studyId, quotaId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotas/${quotaId}/duplicate`, "POST", null).then(r => {
      return r.quota;
    });
  }
  deleteQuota(studyId, quotaId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotas/${quotaId}`, "DELETE", null).then(r => {
      return r.updateTime;
    });
  }
  applyQuotaToSampleFile(studyId, sampleFileId, quotaId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/quotas/${quotaId}/apply`, "POST", null).then(r => {
      return r;
    });
  }
  removeQuotaFromSampleFile(studyId, sampleFileId, quotaId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/quotas/${quotaId}/remove`, "DELETE", null).then(r => {
      return r;
    });
  }
  getQuotaDefinitionVariables(studyId, quotaId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotas/${quotaId}/variables`, "GET", null).then(w => {
      return w.variables;
    });
  }

  exportQuotaReport(studyId, quotaId, asJson) {
    var contentType = asJson ? "application/json" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    var url = `${studyId}/quotas/${quotaId}/export`;
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, url, "POST", null, contentType, true)
      .then(r => {
        if (r.status === 200) return r.blob();
        else throw Error("Error exporting data.");
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        if (asJson) link.setAttribute("target", "_blank");
        else link.setAttribute("download", `QuotaReport_${quotaId}.xlsx`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  exportQuotaSkippedRecords(studyId, sampleFileId, asJson) {
    var contentType = asJson ? "application/json" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    var url = `${studyId}/samplefiles/${sampleFileId}/quotaSkipped/export`;
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, url, "POST", null, contentType, true)
      .then(r => {
        if (r.status === 200) return r.blob();
        else throw Error("Error exporting data.");
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        if (asJson) link.setAttribute("target", "_blank");
        else link.setAttribute("download", `QuotaSkipped_${sampleFileId}.xlsx`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  getSampleFileQuotaCounts(studyId, sampleFileId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/quotas/counts`, "GET").then(r => {
      return r.counts;
    });
  }
  getSampleFileQuotaVerificationCounts(studyId, sampleFileId, quotaId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/samplefiles/${sampleFileId}/quotas/${quotaId}/verification`, "GET").then(r => {
      return r.verification;
    });
  }

  // ////////////////////
  // QUOTA GROUPS
  // ////////////////////

  getQuotaGroups(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotagroups`, "GET", null).then(w => {
      return w.quotaGroups;
    });
  }
  getQuotaGroup(studyId, quotaGroupId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotagroups/${quotaGroupId}`, "GET", null).then(w => {
      return w.quotaGroup;
    });
  }
  renameQuotaGroup(studyId, quotaGroupId, newName) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/quotagroups/${quotaGroupId}/rename`, "POST", {
        name: newName
      })
      .then(r => {
        return r.updateTime;
      });
  }
  deleteQuotaGroup(studyId, quotaGroupId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotagroups/${quotaGroupId}`, "DELETE", null).then(r => {
      return r.updateTime;
    });
  }
  createQuotaGroup(studyId, quotaGroupName) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/quotagroups/create`, "POST", {
        name: quotaGroupName
      })
      .then(r => {
        return r.id;
      });
  }
  duplicateQuotaGroup(studyId, quotaGroupId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotagroups/${quotaGroupId}/duplicate`, "POST", null).then(r => {
      return r.quotaGroup;
    });
  }

  // ////////////////////
  // QUOTA GROUPS - QUOTAS
  // ////////////////////

  getQuotaGroupQuotas(studyId, quotaGroupId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotaGroups/${quotaGroupId}/quotas`, "GET", null).then(r => {
      return r.quotas;
    });
  }
  createQuotaGroupQuota(studyId, quotaGroupId, calc) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotaGroups/${quotaGroupId}/quotas/create`, "POST", calc).then(r => {
      return r.quota;
    });
  }
  renameQuotaGroupQuota(studyId, quotaGroupId, quotaId, newName) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/quotagroups/${quotaGroupId}/quotas/${quotaId}/rename`, "POST", {
        name: newName
      })
      .then(r => {
        return r.updateTime;
      });
  }
  togglePauseQuotaGroupQuota(studyId, quotaGroupId, quotaId, pause) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotagroups/${quotaGroupId}/quotas/${quotaId}/pause/${pause}`, "POST").then(r => {
      return r.updateTime;
    });
  }
  duplicateQuotaGroupQuota(studyId, quotaGroupId, quotaId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotaGroups/${quotaGroupId}/quotas/${quotaId}/duplicate`, "POST", null).then(r => {
      return r.quota;
    });
  }
  updateQuotaGroupQuotaDescription(studyId, quotaGroupId, quotaId, newDescription) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/quotaGroups/${quotaGroupId}/quotas/${quotaId}/update/description`, "POST", {
        description: newDescription
      })
      .then(r => {
        return r.updateTime;
      });
  }
  updateQuotaGroupQuotaDefinition(studyId, quotaGroupId, quotaId, request) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/quotaGroups/${quotaGroupId}/quotas/${quotaId}/updateDefinition`, "POST", request)
      .then(r => {
        return r.updateTime;
      });
  }
  deleteQuotaGroupQuota(studyId, quotaGroupId, quotaId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/quotaGroups/${quotaGroupId}/quotas/${quotaId}`, "DELETE", null).then(r => {
      return r.updateTime;
    });
  }
  applyQuotaGroupToSampleFile(studyId, sampleFileId, quotaGroupId) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/quotaGroups/${quotaGroupId}/apply`, "POST", null)
      .then(r => {
        return r;
      });
  }
  removeQuotaGroupFromSampleFile(studyId, sampleFileId, quotaGroupId) {
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, `${studyId}/sampleFiles/${sampleFileId}/quotaGroups/${quotaGroupId}/remove`, "DELETE", null)
      .then(r => {
        return r;
      });
  }

  // ////////////////////
  // DATA PATCHES
  // ////////////////////

  getStudyDataPatches(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/dataPatches`, "GET", null).then(r => {
      return r;
    });
  }
  getStudyDataPatch(studyId, patchId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/dataPatches/${patchId}`, "GET", null).then(r => {
      return r.dataPatch;
    });
  }
  createStudyDataPatch(studyId, request) {
    var form = new FormData();
    form.append("Name", request.Name);
    form.append("Description", request.Description);
    form.append("Target", JSON.stringify(request.Target));
    form.append("PatchType", request.PatchType);

    if (request.PatchType === "CALCULATION") form.append("Definitions", JSON.stringify(request.Definitions));
    else if (request.PatchType === "FILE") form.append("PatchFile", request.PatchFile);

    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/dataPatches/create`, "POST", form, null).then(r => {
      return r.dataPatch;
    });
  }
  deleteStudyDataPatch(studyId, patchId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/dataPatches/${patchId}`, "DELETE", null).then(r => {
      return r.updateTime;
    });
  }
  applyStudyDataPatch(studyId, patchId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/dataPatches/${patchId}/apply`, "POST", null).then(r => {
      return r.dataPatch;
    });
  }
  getColumnsAvailableToPatch(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/dataPatches/columns`, "GET").then(r => {
      return r.columns;
    });
  }
  revertStudyDataPatches(studyId, dataColumn) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/dataPatches/revert/${dataColumn}`, "POST", null).then(r => {
      return r.updateTime;
    });
  }
  getStudyDataPatchResults(studyId, patchId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/dataPatches/${patchId}/results`, "GET").then(r => {
      return r.results;
    });
  }

  // ////////////////////
  // STUDY SURVEY LINKS
  // ////////////////////

  getUserStudySurveyLinks(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/surveylinks/my`, "GET").then(r => {
      return r.surveyLinks;
    });
  }
  createSurveyTestLinkForUser(studyId, testLink) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/surveylinks`, "POST", testLink).then(r => {
      return r.surveyLinks;
    });
  }
  deleteSurveyTestLinkForUser(studyId, testLink) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/surveylinks/${testLink}`, "DELETE").then(r => {
      return r.updateTime;
    });
  }
  deleteSurveyTestLinkGroupForUser(studyId, linkGroup) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/surveylinks/group/${encodeURIComponent(linkGroup)}`, "DELETE").then(r => {
      return r.updateTime;
    });
  }
  exportSurveyTestLinkGroupToExcel(studyId, linkGroupDate) {
    var url = `${studyId}/surveylinks/group/export`;
    var payload = { GroupDate: linkGroupDate };
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, url, "POST", payload, undefined, true)
      .then(r => {
        if (r.status === 200) return r.blob();
        else throw Error("Error exporting data.");
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `SurveyLinks_${studyId}_${linkGroupDate}.xlsx`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  // ////////////////////
  // STUDY TEMPLATES
  // ////////////////////

  getStudyTemplates() {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, "", "GET").then(r => {
      return r.templates;
    });
  }
  getStudyTemplate(templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, templateId, "GET").then(r => {
      return r.template;
    });
  }
  getStudyTemplateInvitations(templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, `${templateId}/invitationTemplates`, "GET").then(r => {
      return r.templates;
    });
  }
  getStudyTemplateInvitation(templateId, invitationId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, `${templateId}/invitationTemplates/${invitationId}`, "GET").then(r => {
      return r.template;
    });
  }
  getDltTemplates() {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, "dlt-templates", "GET").then(r => {
      return r.templates;
    });
  }
  createStudyTemplate(template) {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, "", "POST", template).then(r => {
      return r.id;
    });
  }
  deleteStudyTemplate(templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, templateId, "DELETE");
  }
  getStudyTemplateCalculations(templateId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, `${templateId}/calculations`, "GET");
  }
  addStudyTemplateCalculation(templateId, calculation) {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, `${templateId}/calculations`, "POST", calculation).then(r => {
      return r.id;
    });
  }
  updateStudyTemplateCalculation(templateId, calculationId, calculation) {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, `${templateId}/calculations/${calculationId}`, "PUT", calculation).then(r => {
      return r.updateTime;
    });
  }
  deleteStudyTemplateCalculation(templateId, calculationId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, `${templateId}/calculations/${calculationId}`, "DELETE");
  }

  // ////////////////////
  // PROJECT TEMPLATES
  // ////////////////////

  getProjectTemplates() {
    return this.fetcher.getJson(API_REQUESTS.PROJECT_TEMPLATES, null, "GET").then(r => {
      return r.templates;
    });
  }

  getProjectTemplate(id) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT_TEMPLATES, id, "GET").then(r => {
      return r.template;
    });
  }

  createProjectTemplate(template) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT_TEMPLATES, null, "POST", template).then(r => {
      return r.id;
    });
  }
  updateProjectTemplate(id, template) {
    return this.fetcher.getJson(API_REQUESTS.PROJECT_TEMPLATES, `${id}`, "PUT", template).then(r => {
      return r.id;
    });
  }

  saveProjectTemplateStepOrder(templateId, stepType, order) {
    return this.fetcher
      .getJson(API_REQUESTS.PROJECT_TEMPLATES, `${templateId}/processingSteps/order?stepType=${stepType.toLowerCase()}`, "PUT", order)
      .then(r => {
        return r;
      });
  }

  // getDltTemplate(templateId) {
  //   return this.fetcher.getJson(API_REQUESTS.STUDY_TEMPLATES, `dlt-template/${templateId}`, "GET").then(r => {
  //     return r.template;
  //   });
  // }

  // ////////////////////
  // SEED LISTS
  // ////////////////////
  getStudySeedLists(studyId) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/seedLists`, "GET").then(r => {
      return r.seedLists;
    });
  }
  // ////////////////////
  // DNC Do-Not-Contact
  // ////////////////////
  getDncForSampleFile(studyId, sampleFileId, isEmail = false, addBrand = false, excel = false) {
    var contentType = excel ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : null;
    var type = isEmail === true ? DNC_TYPES.EMAIL : DNC_TYPES.PHONE;
    var url = `${studyId}/samplefiles/${sampleFileId}/donotcontact/${type.path}?addBrand=${addBrand}`;
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, url, "POST", null, contentType, true)
      .then(r => {
        if (r.status === 200) return r.blob();
        else throw Error("Error exporting data.");
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        var ext = excel === true ? "xlsx" : "json";
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${type.name}DncList_${sampleFileId}.${ext}`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  getEventListForSampleFile(studyId, sampleFileId, type, excel = true) {
    var contentType = excel ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : null;
    var url = `${studyId}/samplefiles/${sampleFileId}/export-event-list?listType=${type}`;
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, url, "POST", null, contentType, true)
      .then(r => {
        if (r.status === 200) return r.blob();
        else throw Error("Error exporting data.");
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        var ext = excel === true ? "xlsx" : "json";
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${type.toLowerCase()}List_${studyId}_${sampleFileId}.${ext}`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  exportSampleFileSurveyLinks(studyId, sampleFileId, campaignId, asJson) {
    var contentType = asJson ? "application/json" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    var url = `${studyId}/samplefiles/${sampleFileId}/campaigns/${campaignId}/surveylinks/export`;
    return this.fetcher
      .getJson(API_REQUESTS.STUDY, url, "POST", null, contentType, true)
      .then(r => {
        if (r.status === 200) return r.blob();
        else throw Error("Error exporting data.");
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        if (asJson) link.setAttribute("target", "_blank");
        else link.setAttribute("download", `SurveyLinks_${sampleFileId}.xlsx`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  // ////////////////////
  // OUTLIERS
  // ////////////////////

  // eslint-disable-next-line no-unused-vars
  getOutlierRunsForWave(_studyId, _waveId) {
    return new Promise(resolve => {
      resolve([]);
    });
  }
  getOutliersForWave(projectId, studyId, waveId, runId) {
    return this.fetcher.getJson(API_REQUESTS.OUTLIERS, `${projectId}/${studyId}/${waveId}/${runId}/outliers`, "GET").then(r => {
      return r.outliers;
    });
  }
  getOutlierRun(projectId, studyId, waveId, runId) {
    return this.fetcher.getJson(API_REQUESTS.OUTLIERS, `${projectId}/${studyId}/${waveId}/${runId}/outlierRun`, "GET");
  }
  getOutlierRunId(projectId, studyId, waveId) {
    return this.fetcher.getJson(API_REQUESTS.OUTLIERS, `${projectId}/${studyId}/${waveId}/outlierRunId`, "GET").then(r => {
      return r.outlierRunId;
    });
  }
  getOutlierCuts(projectId, studyId, waveId, runId) {
    return this.fetcher.getJson(API_REQUESTS.OUTLIERS, `${projectId}/${studyId}/${waveId}/${runId}/outlierCuts`, "GET").then(r => {
      return r.outlierCuts;
    });
  }
  applyOutlierScrubs(projectId, studyId, waveId, runId) {
    return this.fetcher.getJson(API_REQUESTS.OUTLIERS, `${projectId}/${studyId}/${waveId}/${runId}/outlierScrubs`, "PUT");
  }

  // ////////////////////
  // MAILBOXES
  // /////////////////////
  getMailboxes(studyId, verifiedOnly) {
    var query = verifiedOnly ? "?verified=true" : "";
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/mailboxes${query}`, "GET").then(r => {
      return r;
    });
  }
  createMailbox(studyId, mailbox) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/mailboxes/create`, "POST", mailbox).then(r => {
      return r.id;
    });
  }
  updateMailbox(studyId, mailbox) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/mailboxes/update`, "PUT", mailbox).then(r => {
      return r.updateTime;
    });
  }
  deleteMailbox(studyId, id) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/mailboxes/${id}/delete`, "DELETE").then(r => {
      return r.deleteTime;
    });
  }
  sendMailboxVerificationEmail(studyId, id) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/mailboxes/${id}/send-verification-email`, "POST").then(r => {
      return r.emailAddress;
    });
  }
  verifyMailbox(studyId, encData) {
    return this.fetcher.getJson(API_REQUESTS.STUDY, `${studyId}/mailboxes/verify/${encData}`, "POST", null).then(r => {
      return r.verifiedTime;
    });
  }

  //////////////////////
  // DOMAINS
  //////////////////////
  getDomain(domainId) {
    return this.fetcher.getJson(API_REQUESTS.DOMAINS, `${domainId}`, "GET").then(r => {
      return r;
    });
  }
  getSharedDomains() {
    return this.fetcher.getJson(API_REQUESTS.DOMAINS, `shared`, "GET").then(r => {
      return r;
    });
  }
  updateDomain(domainId, studies, shared) {
    var query = shared !== undefined ? `?shared=${shared}` : "";
    return this.fetcher.getJson(API_REQUESTS.DOMAINS, `${domainId}${query}`, "PATCH", studies).then(r => {
      return r;
    });
  }
}

export default new ProjectAdminApi();
